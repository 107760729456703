var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.device.desktop ? "home pa-6 grey lighten-4":"home pa-0 grey lighten-4"},[_c('v-row',[_c('v-col',{attrs:{"col":"12"}},[_c('v-card',{staticClass:"animated animate__fadeInUp rounded-0"},[_c('v-card-title',{class:"flex flex-row-reverse " + _vm.theme.color + " purple lighten-1"},[_c('v-tooltip',{attrs:{"color":_vm.theme.color + " draken-4","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.openCreatePengaduan}},[_vm._v("add_circle")])],1)]}}])},[_c('span',[_vm._v("Tambah Pengaduan")])]),_c('v-tooltip',{attrs:{"color":_vm.theme.color,"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","small":"","icon":""}},on),[_c('v-icon',{attrs:{"color":"white"},on:{"click":_vm.fetchRecords}},[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v("Refresh Data")])]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pencarian","single-line":"","hide-details":"","solo":"","color":_vm.theme.color},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.records,"items-per-page":10,"color":_vm.theme.color,"loading":_vm.loading.table,"loading-text":"Loading... Please wait","search":_vm.search},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","color":value.color}},[_vm._v(_vm._s(value.text))])]}},{key:"item.verified_status",fn:function(ref){
var value = ref.value;
return [_c('v-chip',{attrs:{"small":"","color":value.color}},[_vm._v(_vm._s(value.text))])]}},{key:"item.aksi",fn:function(ref){
var value = ref.value;
return [_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.theme.color}},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical-circle-outline ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openTindakLanjut(value)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-account-hard-hat")]),_vm._v(" Tindak Lanjut ")],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":function($event){return _vm.openEditPengaduan(value.id)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-pencil-circle")]),_vm._v(" Ubah ")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.postDeleteRecord(value.id)}}},[_c('v-list-item-title',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete-circle")]),_vm._v(" Hapus ")],1)],1)],1)],1)]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":_vm.theme.color,"height":"1","indeterminate":""},slot:"progress"})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }