<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row class="pa-1">
      <v-spacer></v-spacer>
      <v-col :cols="device.desktop ? `12` : `12`">
        <v-card class="animated animate__fadeInUp rounded-0">
          <v-card-title
            :class="`white--text  ` + theme.color + ` Plighten-1`"
            style="font-size: 12pt"
          >
            <v-icon
              class="mr-2"
              color="white"
            >mdi-checkbox-blank-circle</v-icon>
            &nbsp;Formulir Laporan Pengaduan
          </v-card-title>

          <v-card-text class="mt-5 font-weight-normal">
            <v-col cols="12">
              <v-row>
                <v-col col="8">
                  <v-text-field
                    outlined
                    :color="theme.color"
                    :hide-details="device.desktop"
                    label="Tanggal"
                    v-model="record.tanggal"
                    dense
                    type="date"
                  ></v-text-field>
                </v-col>
                <v-col col="4">
                  <v-text-field
                    outlined
                    :color="theme.color"
                    :hide-details="device.desktop"
                    label="Jam"
                    v-model="record.jam"
                    dense
                    type="time"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col col="12">
              <v-text-field
                outlined
                :color="theme.color"
                :hide-details="device.desktop"
                label="Nama Pelapor"
                v-model="record.nama_pelapor"
                dense
              ></v-text-field>
            </v-col>
            <v-col col="12">
              <v-text-field
                outlined
                :color="theme.color"
                :hide-details="device.desktop"
                label="Nomor Telpon Pelapor"
                v-model="record.nomor_telp_pelapor"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Alamat Pelapor"
                outlined
                dense
                hide-details
                v-model="record.alamat_pelapor"
                rows="2"
                :color="theme.color"
              >{{ record.alamat_pelapor }}</v-textarea>
            </v-col>
            <v-col col="12">
              <v-text-field
                outlined
                :color="theme.color"
                :hide-details="device.desktop"
                label="Nama Penerima Laporan"
                v-model="record.nama_penerima_laporan"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                label="Jenis Kedaruratan"
                outlined
                dense
                hide-details
                v-model="record.jenis_kejadian_id"
                :items="jeniskejadians"
                :color="theme.color"
                @change="fetchKejadian"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                label="Kejadian"
                outlined
                dense
                hide-details
                v-model="record.kejadian_id"
                :items="kejadians"
                :color="theme.color"
                append-outer-icon="mdi-plus-circle"
                @click:append-outer="openKejadian"
              ></v-select>
            </v-col>
            <v-col col="12">
              <v-textarea
                outlined
                :color="theme.color"
                :hide-details="device.desktop"
                label="Penyebab Kejadian"
                v-model="record.penyebab_kejadian"
                dense
                rows="3"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <div class="subtitle-2">LOKASI KEJADIAN</div>
            </v-col>
            <v-col cols="12">
              <v-select
                label="Kecamatan"
                outlined
                dense
                hide-details
                v-model="record.district_id"
                :items="kecamatans"
                @change="fetchDesas"
                :color="theme.color"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                label="Desa"
                outlined
                dense
                hide-details
                v-model="record.village_id"
                :items="desas"
                :color="theme.color"
              ></v-select>
            </v-col>
            <v-col col="12">
              <v-text-field
                outlined
                :color="theme.color"
                :hide-details="device.desktop"
                label="Kampung"
                v-model="record.kampung"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    :color="theme.color"
                    :hide-details="device.desktop"
                    label="RT"
                    v-model="record.rt"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    :color="theme.color"
                    :hide-details="device.desktop"
                    label="RW"
                    v-model="record.rw"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12">
              <v-row class="justify-center mb-10">
                <div class="title-geoinfo">PETA DATA KEBENCANAAN</div>
                <v-col cols="12">
                  <l-map
                    style="height: 500px;z-index: 0;"
                    :zoom="zoom"
                    :center="center"
                  >
                    <v-geosearch
                      :options="geosearchOptions"
                      style="width:100px; border: 1px;"
                    ></v-geosearch>
                    <l-tile-layer
                      :url="url"
                      :attribution="attribution"
                    ></l-tile-layer>
                    <l-marker
                      v-for="item in markers"
                      :key="item.id"
                      :visible="item.visible"
                      :draggable="item.draggable"
                      :lat-lng.sync="item.position"
                    >

                      <l-icon
                        iconSize=32
                        icon-url="/images/icon-marker-merah.png"
                      />
                      <l-popup :content="item.tooltip" />
                      <l-tooltip :content="item.tooltip" />
                    </l-marker>
                  </l-map>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    outlined
                    :color="theme.color"
                    :hide-details="device.desktop"
                    label="Kawasan /Lokasi "
                    v-model="record.kawasan"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    outlined
                    :color="theme.color"
                    :hide-details="device.desktop"
                    label="Garis Lintang "
                    v-model="record.lat"
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    outlined
                    :color="theme.color"
                    :hide-details="device.desktop"
                    label="Garis Bujur "
                    v-model="record.lng"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-col class="text-right">
              <v-btn
                outlined
                :color="theme.color"
                @click="postRecord"
              >
                <v-icon>mdi-content-save</v-icon> SIMPAN
              </v-btn>
              <v-btn
                class="ml-2"
                outlined
                color="grey"
                @click="
                  $router.push({
                    name: 'operator-pengaduan',
                  })
                "
              >
                <v-icon>cancel</v-icon>TUTUP
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="kejadian.form.show"
        :max-width="device.desktop ? `600px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar
            :color="theme.color"
            dark
          >Formulir Tambah Kejadian
          </v-toolbar>
          <v-card-title class="justify-center">DATA KEJADIAN</v-card-title>
          <v-card-text>
            <v-col cols=12>
              <v-text-field
                label="Kejadian"
                outlined
                dense
                hide-details
                v-model="record.kejadian"
                :color="theme.color"
              ></v-text-field>
            </v-col>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
              outlined
              :color="theme.color"
              v-show="!edit"
              @click="postKejadian"
            >Simpan</v-btn>
            <v-btn
              outlined
              :color="theme.color"
              v-show="edit"
              @click="postKejadian"
            >Ubah</v-btn>
            <v-btn
              outlined
              color="grey"
              @click="kejadian.form.show = false"
            >Batal</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup,
  LTooltip,
} from "vue2-leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import VGeosearch from "vue2-leaflet-geosearch";
import "leaflet/dist/leaflet.css";
export default {
  name: "profil-perusahaan",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    VGeosearch,
    LIcon,
    LPopup,
    LTooltip,
  },
  data: () => ({
    record: {
      jenis_kejadian_id: null,
    },
    jeniskejadians: [],
    kejadians: [],
    kecamatans: [],
    desas: [],
    kejadian: {
      form: {
        show: false,
      },
    },

    url: "https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    zoom: 10,

    center: [-6.2603947772003155, 106.48229114604028],

    geosearchOptions: {
      // Important part Here
      provider: new OpenStreetMapProvider(),
    },

    marker: {
      id: 1,
      position: { lat: -6.2603947772003155, lng: 106.48229114604028 },
      tooltip: "Pilih Lokasi Kejadian",
      draggable: true,
      visible: true,
    },

    markers: [],
  }),

  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "records",
      "loading",
      "event",
      "snackbar",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/",
      pagination: false,
      title: "FORMULIR LAPORAN",
      subtitle: "Formulir Pengisian Laporan Kedaruratan dan Kebencanaan",

      breadcrumbs: [
        {
          text: "Laporan Kedaruratan",
          disabled: false,
          href: "",
        },
        {
          text: "Formulir ",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        export: false,
        print: false,
      },
    });
    this.fetchRecord();
    this.fetchJenisKejadians();
    this.fetchKecamatans();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "assignFileBrowse",
      "getUserInfo",
      "initUploadLibrary",
      "setRecord",
      "setLoading",
    ]),
    fetchRecord: async function () {
      let { data } = await this.http.get(
        "api/operator/pengaduan/" + this.$route.params.id
      );
      this.record = data;
      const datamaps = {
        id: 1,
        position: { lat: data.lat, lng: data.lng },
        tooltip: "Pilih Lokasi Kejadian",
        draggable: true,
        visible: true,
      };

      this.marker["position"] = { lat: data.lat, lng: data.lng };
      this.markers.push(this.marker);
    },
    postRecord: async function () {
      try {
        this.setLoading({ dialog: true, text: "Proses Ubah Pengaduan" });
        let {
          data: { status, message },
        } = await this.http.post(
          "api/operator/pengaduan/" + this.record.id + "?_method=PUT",
          this.record
        );
        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
        this.$router.push({ name: "operator-pengaduan" });
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = "Opps..., terjadi kesalahan " + error;
        this.snackbar.state = true;
      } finally {
        this.setLoading({ dialog: false, text: "" });
      }
    },
    fetchUpdate: async function () {
      try {
        this.setLoading({
          dialog: true,
          text: "Proses verifikasi data permohonan",
        });
        let {
          data: { status, message, data },
        } = await this.http.post(
          "api/supervisor/pemeriksaan-permohonan/" +
            this.$route.params.id +
            "?_method=PUT",
          this.record
        );

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;

        this.record = data;
        this.$router.push({ name: "supervisor-verifikasi-permohonan-index" });
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = error;
        this.snackbar.state = true;
      } finally {
        this.setLoading({ dialog: false, text: "" });
      }
    },
    fetchJenisKejadians: async function () {
      try {
        let { data } = await this.http.post("api/master-data/combo-jenis");
        this.jeniskejadians = data;
      } catch (error) {}
    },
    fetchKejadian: async function () {
      try {
        let { data } = await this.http.post("api/master-data/combo-kejadian", {
          jenis_kejadian_id: this.record.jenis_kejadian_id,
        });
        this.kejadians = data;
      } catch (error) {}
    },
    fetchKecamatans: async function () {},
    fetchDesas: async function () {},
    openKejadian: function () {
      this.record.kejadian = null;
      this.kejadian.form.show = true;
    },
    postKejadian: async function () {
      try {
        let {
          data: { status, message },
        } = await this.http.post(
          "api/master-data/kejadian/" + this.record.jenis_kejadian_id,
          { name: this.record.kejadian }
        );
        if (!status) {
          this.snackbar.color = "orange";
          this.snackbar.text = message;
          this.snackbar.state = true;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
        this.kejadian.form.show = false;
        this.fetchKejadian();
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = "Opps..., terjadi kesalahan" + error;
        this.snackbar.state = true;
      }
    },
    fetchKecamatans: async function () {
      let { data } = await this.http.post("api/master-data/combo-kecamatan");
      this.kecamatans = data;
    },
    fetchDesas: async function () {
      let { data } = await this.http.post(
        "api/master-data/combo-desa",
        this.record
      );
      this.desas = data;
    },
  },
  watch: {
    "record.district_id": function () {
      this.fetchDesas();
    },
    "record.jenis_kejadian_id": function () {
      this.fetchKejadian();
    },
    "marker.position": {
      handler() {
        this.record.lat = this.marker.position.lat;
        this.record.lng = this.marker.position.lng;
      },
      deep: true,
    },
  },
};
</script>

<style></style>
