<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">
    <v-row>
      <v-col col="12">
        <v-card class="animated animate__fadeInUp rounded-0">
          <v-card-title :class="
              `flex flex-row-reverse ` + theme.color + ` purple lighten-1`
            ">
            <v-tooltip
              :color="theme.color + ` draken-4`"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="openCreatePengaduan"
                  >add_circle</v-icon>
                </v-btn>
              </template>
              <span>Tambah Pengaduan</span>
            </v-tooltip>

            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="fetchRecords"
                  >refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              solo
              :color="theme.color"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.status="{ value }">
              <v-chip
                small
                :color="value.color"
              >{{value.text}}</v-chip>
            </template>
            <template v-slot:item.verified_status="{ value }">
              <v-chip
                small
                :color="value.color"
              >{{value.text}}</v-chip>
            </template>

            <template v-slot:item.aksi="{ value }">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="theme.color"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-dots-vertical-circle-outline
                  </v-icon>
                </template>

                <v-list>
                  <v-list-item @click="openTindakLanjut(value)">
                    <v-list-item-title>
                      <v-icon color="purple">mdi-account-hard-hat</v-icon>
                      Tindak Lanjut
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="openEditPengaduan(value.id)">
                    <v-list-item-title>
                      <v-icon color="orange">mdi-pencil-circle</v-icon>
                      Ubah
                    </v-list-item-title>
                  </v-list-item>

                  <v-list-item @click="postDeleteRecord(value.id)">
                    <v-list-item-title>
                      <v-icon color="red">mdi-delete-circle</v-icon>
                      Hapus
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num", width: 50, align: "right" },
      { text: "POS", value: "pos" },
      { text: "KEJADIAN", value: "name" },
      { text: "LOKASI", value: "lokasi" },
      {
        text: "WAKTU",
        value: "waktu",
        align: "center",
        sortable: false,
        width: 200,
      },
      {
        text: "VERIFIKASI",
        value: "verified_status",
        align: "center",
        sortable: false,
        width: 200,
      },
      {
        text: "STATUS",
        value: "status",
        align: "center",
        sortable: false,
        width: 200,
      },

      {
        text: "AKSI",
        value: "aksi",
        align: "center",
        width: 100,
        sortable: false,
      },
    ],

    search: null,
    flname: null,

    add: false,
    edit: false,

    selected: false,

    jenis: [],

    kecamatans: [],
    desas: [],
  }),
  computed: {
    ...mapState([
      "page",
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "page",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/operator/pengaduan",
      pagination: false,
      title: "LAPORAN KEDARURATAN",
      subtitle: "Berikut Daftar Seluruh Laporan Kedaruratan Yang Tersedia",

      breadcrumbs: [
        {
          text: "Laporan Kedaruratan",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        export: false,
        print: false,
      },
    });
    this.fetchRecords();
    this.fetchJenis();
    this.fetchKecamatans();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
      "assignFileBrowse",
      "setRecord",
    ]),
    addNewRecord: function () {
      this.setRecord({});
      this.slug = null;
      this.add = true;
      this.edit = false;
    },
    postAddNewRecord: function () {
      this.postAddNew(this.record).then(() => {
        this.fetchRecords();
        this.path = "";
        this.add = false;
      });
    },
    editRecord: function (val) {
      this.postEdit(val).then(() => {
        this.flname = this.record.flname;
      });

      this.add = true;
      this.edit = true;
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.path = "";
        this.add = false;
        this.edit = false;
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    uploadFile: function () {
      this.assignFileBrowse({
        fileType: [".jpeg", ".jpg", ".png", ".pdf"],
        query: {
          doctype: "dokumen",
        },
        callback: (response) => {
          setTimeout(() => {
            this.flname = response.name;
            this.record.flname = response.name;
          }, 500);
        },
      });
    },

    postDownload(val) {
      window.open(val, "__blank");
    },

    fetchJenis: async function () {
      let { data } = await this.http.post("api/master-data/combo-jenis");
      this.jenis = data;
    },
    fetchKecamatans: async function () {
      let { data } = await this.http.post("api/master-data/combo-kecamatan");
      this.kecamatans = data;
    },
    fetchDesas: async function () {
      let { data } = await this.http.post(
        "api/master-data/combo-desa",
        this.record
      );
      this.desas = data;
    },
    openCreatePengaduan: function () {
      this.$router.push({ name: "operator-pengaduan-create" });
    },

    openEditPengaduan: function (val) {
      this.$router.push({
        name: "operator-pengaduan-edit",
        params: {
          id: val,
        },
      });
    },

    openTindakLanjut: function (val) {
      if (val.jenis == "KBR") {
        this.$router.push({
          name: "operator-tindak-lanjut-kebakaran",
          params: {
            pengaduan_id: val.id,
          },
        });
      }
      if (val.jenis == "KBN") {
        this.$router.push({
          name: "operator-tindak-lanjut-kebencanaan",
          params: {
            pengaduan_id: val.id,
          },
        });
      }
      if (val.jenis == "PNY") {
        this.$router.push({
          name: "operator-tindak-lanjut-penyelamatan",
          params: {
            pengaduan_id: val.id,
          },
        });
      }
    },
  },
};
</script>
