<template>
  <div class="home pa-6">
    <v-flex
      row
      class="pb-2"
      v-if="device.desktop"
    >
      <v-icon :color="theme.color">mdi-hand</v-icon>
      <v-subheader class="text-h6">
        <h4>LAPORAN KEDARURATAN</h4>
      </v-subheader>
    </v-flex>
    <v-flex
      class="pb-5 pl-7"
      v-if="device.desktop"
    >
      <div class="grey--text mb-2">
        Daftar Seluruh Laporan Kedaruratan Yang Dimiliki Anda
      </div>
    </v-flex>

    <v-row v-if="device.desktop">
      <v-col col="12">
        <v-card>
          <v-card-title :class="
              `flex flex-row-reverse ` + theme.color + ` purple lighten-1`
            ">

            <v-tooltip
              :color="theme.color"
              bottom
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  small
                  icon
                  v-on="on"
                >
                  <v-icon
                    color="white"
                    @click="fetchRecords"
                  >refresh</v-icon>
                </v-btn>
              </template>
              <span>Refresh Data</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Pencarian"
              single-line
              hide-details
              solo
              :color="theme.color"
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="records"
            :items-per-page="10"
            class="elevation-2"
            :color="theme.color"
            :loading="loading.table"
            loading-text="Loading... Please wait"
            :search="search"
          >
            <v-progress-linear
              slot="progress"
              :color="theme.color"
              height="1"
              indeterminate
            ></v-progress-linear>
            <template v-slot:item.verified_status="{ value }">
              <v-chip
                small
                :color="value.color"
              >{{value.text}}</v-chip>
            </template>
            <template v-slot:item.status="{ value }">
              <v-chip
                small
                :color="value.color"
              >{{value.text}}</v-chip>
            </template>

            <template v-slot:item.aksi="{ value }">
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :color="theme.color"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-dots-vertical-circle-outline
                  </v-icon>
                </template>

                <v-list>
                  <v-list-item @click="openTindakLanjut(value)">
                    <v-list-item-title>
                      <v-icon color="purple">mdi-account-hard-hat</v-icon>
                      Tindak Lanjut
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="postSetTerverifikasi(value.id)">
                    <v-list-item-title>
                      <v-icon color="purple">mdi-shield-check-outline</v-icon>
                      Set Terverifikasi
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="postSetUnTerverifikasi(value.id)">
                    <v-list-item-title>
                      <v-icon color="purple">mdi-shield-remove-outline</v-icon>
                      Batalkan Verifikasi
                    </v-list-item-title>
                  </v-list-item>

                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="device.mobile">
      <v-app-bar
        app
        :color="theme.color + ` draken-4`"
        dark
      >
        <v-icon
          @click="$router.push({ name: 'dashboard' })"
          class="mr-2"
        >mdi-arrow-left-bold-circle-outline</v-icon>
        <v-toolbar-title class="text">
          {{ page.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="openCreatePengaduan">mdi-plus-circle</v-icon>
      </v-app-bar>

      <v-col
        cols="12"
        class="pa-0"
      >
        <v-list two-line>
          <template v-for="(item, index) in records">
            <v-list-item :key="item.id">
              <v-list-item-content>
                <v-list-item-title
                  v-html="item.name"
                  class="font-weight-bold text-subtitle-2"
                ></v-list-item-title>
                <v-list-item-subtitle>{{ item.lokasi }}</v-list-item-subtitle>
                <v-list-item-subtitle v-html="item.waktu"></v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-chip
                    small
                    :color="item.status.color"
                  >{{item.status.text}}</v-chip>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-menu
                  bottom
                  origin="center center"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="theme.color"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-dots-vertical-circle-outline
                    </v-icon>
                  </template>

                  <v-list>
                    <v-list-item @click="openTindakLanjut(item.aksi)">
                      <v-list-item-title>
                        <v-icon color="purple">mdi-account-hard-hat</v-icon>
                        Tindak Lanjut
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="openEditPengaduan(item.id)">
                      <v-list-item-title>
                        <v-icon color="green">mdi-shield-account-outline</v-icon>
                        Edit
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item @click="postDeleteRecord(item.id)">
                      <v-list-item-title>
                        <v-icon color="red">mdi-delete</v-icon>
                        Hapus
                      </v-list-item-title>

                    </v-list-item>
                    <v-list-item @click="postSetTerverifikasi(item.id)">
                      <v-list-item-title>
                        <v-icon color="purple">mdi-shield-check-outline</v-icon>
                        Set Terverifikasi
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="postSetUnTerverifikasi(item.id)">
                      <v-list-item-title>
                        <v-icon color="purple">mdi-shield-remove-outline</v-icon>
                        Batalkan Verifikasi
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Home",
  data: () => ({
    num: 1,
    headers: [
      { text: "#", value: "num", width: 50, align: "right" },
      { text: "POS", value: "pos" },
      { text: "KEJADIAN", value: "name" },
      { text: "LOKASI", value: "lokasi" },
      {
        text: "WAKTU",
        value: "waktu",
        align: "center",
        sortable: false,
        width: 200,
      },
      {
        text: "VERIFIKASI",
        value: "verified_status",
        align: "center",
        sortable: false,
        width: 200,
      },
      {
        text: "STATUS",
        value: "status",
        align: "center",
        sortable: false,
        width: 200,
      },
      {
        text: "AKSI",
        value: "aksi",
        align: "center",
        width: 100,
        sortable: false,
      },
    ],

    search: null,
    flname: null,

    add: false,
    edit: false,

    selected: false,

    jenis: [],

    kecamatans: [],
    desas: [],
  }),
  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "record",
      "records",
      "loading",
      "event",
      "page",
      "snackbar",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/operator/pengaduan",
      title: "Dokumen Lampiran",
      pagination: false,
    });
    this.fetchRecords();
    this.fetchJenis();
    this.fetchKecamatans();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "fetchRecords",
      "postAddNew",
      "postEdit",
      "postUpdate",
      "postConfirmDelete",
      "assignFileBrowse",
      "setRecord",
    ]),
    addNewRecord: function () {
      this.setRecord({});
      this.slug = null;
      this.add = true;
      this.edit = false;
    },
    postAddNewRecord: function () {
      this.postAddNew(this.record).then(() => {
        this.fetchRecords();
        this.path = "";
        this.add = false;
      });
    },
    editRecord: function (val) {
      this.postEdit(val).then(() => {
        this.flname = this.record.flname;
      });

      this.add = true;
      this.edit = true;
    },

    postUpdateRecord: function () {
      this.postUpdate(this.record).then(() => {
        this.fetchRecords();
        this.path = "";
        this.add = false;
        this.edit = false;
      });
    },

    postDeleteRecord: function (val) {
      this.postConfirmDelete(val);
    },

    uploadFile: function () {
      this.assignFileBrowse({
        fileType: [".jpeg", ".jpg", ".png", ".pdf"],
        query: {
          doctype: "dokumen",
        },
        callback: (response) => {
          setTimeout(() => {
            this.flname = response.name;
            this.record.flname = response.name;
          }, 500);
        },
      });
    },

    postDownload(val) {
      window.open(val, "__blank");
    },

    fetchJenis: async function () {
      let { data } = await this.http.post("api/master-data/combo-jenis");
      this.jenis = data;
    },
    fetchKecamatans: async function () {
      let { data } = await this.http.post("api/master-data/combo-kecamatan");
      this.kecamatans = data;
    },
    fetchDesas: async function () {
      let { data } = await this.http.post(
        "api/master-data/combo-desa",
        this.record
      );
      this.desas = data;
    },
    openCreatePengaduan: function () {
      this.$router.push({ name: "operator-pengaduan-create" });
    },
    openEditPengaduan: function (val) {
      this.$router.push({
        name: "danru-pengaduan-edit",
        params: {
          id: val,
        },
      });
    },

    openTindakLanjut: function (val) {
      if (val.jenis == "KBR") {
        this.$router.push({
          name: "danru-tindak-lanjut-kebakaran",
          params: {
            pengaduan_id: val.id,
          },
        });
      }
      if (val.jenis == "KBN") {
        this.$router.push({
          name: "danru-tindak-lanjut-kebencanaan",
          params: {
            pengaduan_id: val.id,
          },
        });
      }
      if (val.jenis == "PNY") {
        this.$router.push({
          name: "danru-tindak-lanjut-penyelamatan",
          params: {
            pengaduan_id: val.id,
          },
        });
      }
    },

    postSetTerverifikasi: async function (val) {
      try {
        const {
          data: { status, message },
        } = await this.http.post("api/operator/pengaduan-set-terverifikasi", {
          id: val,
        });

        if (!status) {
          this.snackbar.color = "oranger";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
        this.fetchRecords();
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = message;
        this.snackbar.state = true;
      }
    },

    postSetUnTerverifikasi: async function (val) {
      try {
        const {
          data: { status, message },
        } = await this.http.post("api/operator/pengaduan-set-un-verifikasi", {
          id: val,
        });

        if (!status) {
          this.snackbar.color = "oranger";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
        this.fetchRecords();
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = message;
        this.snackbar.state = true;
      }
    },
  },
};
</script>
