<script>
import { Bar } from "vue-chartjs";
import { mapActions, mapState } from "vuex";

export default {
  name: "permohonan-bulan",
  extends: Bar,

  computed: {
    ...mapState(["http"]),
  },

  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData: async function () {
      let { data } = await this.http.get("api/dashboard-pengaduan-per-bulan");
      this.renderChart(
        {
          labels: [
            "Januari",
            "Pebruari",
            "Maret",
            "April",
            "Mei",
            "Juni",
            "July",
            "Augustus",
            "September",
            "Oktober",
            "Nopember",
            "Desember",
          ],
          datasets: [
            {
              label: "Statistik Kedaruratan Per Bulan 2023",
              backgroundColor: "#4d4dff",
              data: data, //[40, 20, 12, 39, 20, 40, 39, 80, 40, 20, 12, 11],
            },
          ],
        },
        { responsive: true, maintainAspectRatio: false }
      );
    },
  },
};
</script>
