<template>
  <div :class="device.desktop ? `home pa-6 grey lighten-4`:`home pa-0 grey lighten-4`">

    <v-row class="pa-1">
      <v-spacer></v-spacer>
      <v-col :cols="device.desktop ? `12` : `12`">
        <v-card class="animated animate__fadeInUp rounded-0">
          <v-card-title
            :class="`white--text  ` + theme.color + ` Plighten-1`"
            style="font-size: 12pt"
          >
            <v-icon
              class="mr-2"
              color="white"
            >mdi-checkbox-blank-circle</v-icon>
            &nbsp;
            <span v-show="device.desktop">
              Formulir Tindak Lanjut Laporan Kebakaran
            </span>
            <span v-show="device.mobile">
              Formulir
            </span>
          </v-card-title>

          <v-card-text class="mt-5 font-weight-normal">
            <v-tabs
              v-model="tab"
              :color="theme.color"
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-1">DATA TINDAK LANJUT</v-tab>
              <v-tab href="#tab-2">UNGGAH LAMPIRAN</v-tab>
              <v-tab-item value="tab-1">
                <v-row class="mt-2">
                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">WAKTU PERSIAPAN</div>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col :cols="device.desktop? 6:12">
                        <v-text-field
                          label="Dari Jam"
                          outlined
                          :filled="record.waktu_persiapan_mulai"
                          dense
                          hide-details
                          v-model="record.waktu_persiapan_mulai"
                          :color="theme.color"
                          type="time"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop? 6:12">
                        <v-text-field
                          label="Sampai Dengan Jam"
                          outlined
                          :filled="record.waktu_persiapan_selesai"
                          dense
                          hide-details
                          v-model="record.waktu_persiapan_selesai"
                          :color="theme.color"
                          type="time"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">KEBERANGKATAN</div>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col :cols="device.desktop? 6:12">
                        <v-text-field
                          label="Jam Keberangkatan"
                          outlined
                          :filled="record.waktu_keberangkatan"
                          dense
                          hide-details
                          v-model="record.waktu_keberangkatan"
                          :color="theme.color"
                          type="time"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop? 6:12">
                        <v-text-field
                          label="Jam Sampai Dilokasi"
                          outlined
                          :filled="record.waktu_sampai_dilokasi"
                          dense
                          hide-details
                          v-model="record.waktu_sampai_dilokasi"
                          :color="theme.color"
                          type="time"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">PENANGANAN</div>
                  </v-col>
                  <v-col cols=12>
                    <v-row>
                      <v-col :cols="device.desktop ?  6:12">
                        <v-text-field
                          label="Tanggal Mulai Penangan"
                          outlined
                          :filled="record.dari_tanggal"
                          dense
                          hide-details
                          v-model="record.dari_tanggal"
                          :color="theme.color"
                          type="date"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ?  6:12">
                        <v-text-field
                          label="Tanggal Akhir Penangan"
                          outlined
                          :filled="record.sampai_tanggal"
                          dense
                          hide-details
                          v-model="record.sampai_tanggal"
                          :color="theme.color"
                          type="date"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">

                    <v-row>
                      <v-col :cols="device.desktop ?  6:12">
                        <v-text-field
                          label="Mulai Penangan"
                          outlined
                          :filled="record.waktu_penanganan_mulai"
                          dense
                          hide-details
                          v-model="record.waktu_penanganan_mulai"
                          :color="theme.color"
                          type="time"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ?  6:12">
                        <v-text-field
                          label="Selesai Penangan"
                          outlined
                          :filled="record.waktu_penanganan_selesai"
                          dense
                          hide-details
                          v-model="record.waktu_penanganan_selesai"
                          :color="theme.color"
                          type="time"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col :cols="device.desktop ?  6:12">
                        <v-text-field
                          label="Lama Penanganan"
                          outlined
                          :filled="record.lama_penanganan_jam"
                          dense
                          hide-details
                          v-model="record.lama_penanganan_jam"
                          :color="theme.color"
                          suffix="JAM"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ?  6:12">
                        <v-text-field
                          label="Lama Penanganan"
                          outlined
                          :filled="record.lama_penanganan_menit"
                          dense
                          hide-details
                          v-model="record.lama_penanganan_menit"
                          :color="theme.color"
                          suffix="MENIT"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col :cols="device.desktop ?  6:12">
                    <v-text-field
                      label="Jam Kedatangan Renkar"
                      outlined
                      :filled="record.jam_kedatangan_red_car"
                      dense
                      hide-details
                      v-model="record.jam_kedatangan_red_car"
                      :color="theme.color"
                      type="time"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">KORBAN KEBAKARAN</div>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="justify-end mr-2">
                      <v-tooltip
                        :color="theme.color"
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            text
                            small
                            icon
                            v-on="on"
                          >
                            <v-icon
                              :color="theme.color"
                              @click="openKorban"
                            >mdi-plus-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Tambah Korban</span>
                      </v-tooltip>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                      :headers="korbans.headers"
                      :items="korbans.records"
                      :items-per-page="10"
                      class="elevation-2"
                      :color="theme"
                      :loading="loading.table"
                      loading-text="Loading... Please wait"
                      :search="korbans.search"
                    >
                      <v-progress-linear
                        slot="progress"
                        :color="theme.color"
                        height="1"
                        indeterminate
                      ></v-progress-linear>

                      <template v-slot:item.flname_path="{ value }">
                        <v-icon
                          :color="theme.color"
                          @click="postDownload(value)"
                        >cloud_download</v-icon>
                      </template>

                      <template v-slot:item.id="{ value }">

                        <v-tooltip
                          color="red"
                          bottom
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              text
                              small
                              icon
                              v-on="on"
                            >
                              <v-icon
                                color="red"
                                @click="postDeleteKorban(value)"
                              >mdi-delete-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Hapus</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-col>

                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">TEAM PENANGANAN</div>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Jumlah Petugas Yang Menangani"
                      outlined
                      :filled="record.jumlah_petugas"
                      dense
                      hide-details
                      v-model="record.jumlah_petugas"
                      :color="theme.color"
                      type="number"
                      suffix="ORANG"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Asal Pos Yang Menangani"
                      outlined
                      dense
                      hide-details
                      rows="2"
                      v-model="record.asal_pos"
                      :color="theme.color"
                    >{{ record.asal_pos }}</v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Unsur Yang Terlibat Penanganan"
                      outlined
                      dense
                      hide-details
                      rows="2"
                      v-model="record.unsur_yang_terlibat"
                      :color="theme.color"
                    >{{ record.unsur_yang_terlibat }}</v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">KERUGIAN</div>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                      label="Jenis yang terbakar"
                      outlined
                      :filled="record.jenis_bangunan_yang_terbakar"
                      v-model="record.jenis_bangunan_yang_terbakar"
                      :color="theme.color"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Prakiraan Jumlah Kerugian Material (Rp.)"
                      outlined
                      :filled="record.prakiraan_jumlah_kerugian_material"
                      v-model="record.prakiraan_jumlah_kerugian_material"
                      :color="theme.color"
                      type="number"
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">PERALATAN</div>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Peralatan Yang Dibutuhkan"
                      outlined
                      dense
                      hide-details
                      rows="2"
                      v-model="record.peralatan_yang_digunakan"
                      :color="theme.color"
                    >{{ record.peralatan_yang_digunakan }}</v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">KONDISI</div>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      label="Kondisi Saat Ini"
                      outlined
                      dense
                      hide-details
                      rows="3"
                      v-model="record.situasi_terkini"
                      :color="theme.color"
                    >{{ record.situasi_terkini }}</v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Permasalahan Yang Dihadapi"
                      outlined
                      dense
                      hide-details
                      rows="3"
                      v-model="record.permasalahan_yang_dihadapi"
                      :color="theme.color"
                    >{{ record.permasalahan_yang_dihadapi }}</v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Saran-saran"
                      outlined
                      dense
                      hide-details
                      rows="3"
                      v-model="record.saran_saran"
                      :color="theme.color"
                    >{{ record.saran_saran }}</v-textarea>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-row class="mt-2">
                  <v-col cols="12">
                    <div class="h6">HASIL DOKUMENTASI</div>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="justify-end mr-2">
                      <v-tooltip
                        :color="theme.color"
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            text
                            small
                            icon
                            v-on="on"
                          >
                            <v-icon
                              :color="theme.color"
                              @click="openDokumen"
                            >mdi-plus-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Tambah Dokumen Laporan</span>
                      </v-tooltip>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                      :headers="dokumen.headers"
                      :items="dokumen.records"
                      :items-per-page="10"
                      class="elevation-2"
                      :color="theme"
                      :loading="loading.table"
                      loading-text="Loading... Please wait"
                      :search="dokumen.search"
                    >
                      <v-progress-linear
                        slot="progress"
                        :color="theme.color"
                        height="1"
                        indeterminate
                      ></v-progress-linear>

                      <template v-slot:item.flname_path="{ value }">
                        <v-icon
                          :color="theme.color"
                          @click="postDownload(value)"
                        >cloud_download</v-icon>
                      </template>

                      <template v-slot:item.id="{ value }">
                        <v-tooltip
                          color="purple"
                          bottom
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              text
                              small
                              icon
                              v-on="on"
                            >
                              <v-icon
                                color="purple"
                                @click="postDownload(value)"
                              >mdi-cloud-download</v-icon>
                            </v-btn>
                          </template>
                          <span>Lihat Dokumen</span>
                        </v-tooltip>
                        <v-tooltip
                          color="green"
                          bottom
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              text
                              small
                              icon
                              v-on="on"
                            >
                              <v-icon
                                color="red"
                                @click="postDeleteRecord(value)"
                              >mdi-delete-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Hapus</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-col class="text-right">
              <v-btn
                outlined
                :color="theme.color"
                @click="postRecord"
              >
                <v-icon>mdi-content-save</v-icon> SIMPAN
              </v-btn>
              <v-btn
                class="ml-2"
                outlined
                color="grey"
                @click="
                  $router.push({
                    name: 'operator-pengaduan',
                  })
                "
              >
                <v-icon>cancel</v-icon>TUTUP
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="dokumen.form.show"
        :max-width="device.desktop ? `600px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar
            :color="theme.color"
            dark
          >Formulir Upoad Dokumen Laporan</v-toolbar>
          <v-card-text>
            <v-col
              col="12"
              class="mt-3"
            >
              <v-text-field
                outlined
                :color="theme.color"
                :hide-details="device.desktop"
                label="Nama Dokumen"
                v-model="dokumen.record.name"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="File Foto (Max: 2 MB)"
                append-outer-icon="attachment"
                v-model="dokumen.path"
                :color="theme.color"
                @click:append-outer="uploadFile"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
              outlined
              :color="theme.color"
              v-show="!edit"
              @click="postDokumenLaporan"
            >Tambah</v-btn>
            <v-btn
              outlined
              :color="theme.color"
              v-show="edit"
              @click="postUpdateRecord"
            >Ubah</v-btn>
            <v-btn
              outlined
              color="grey"
              @click="dokumen.form.show = false"
            >Batal</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="korbans.form.show"
        :max-width="device.desktop ? `600px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar
            :color="theme.color"
            dark
          >Formulir Korban Kebakaran</v-toolbar>
          <v-row class="justify-center mt-3 mb-2">
            <div class="headline">DATA KORBAN</div>
          </v-row>
          <v-card-text>
            <v-col col="12">
              <v-text-field
                outlined
                :color="theme.color"
                hide-details
                label="NIK"
                v-model="korbans.record.nik"
                dense
              ></v-text-field>
            </v-col>
            <v-col col="12">
              <v-text-field
                outlined
                :color="theme.color"
                hide-details
                label="Nama"
                v-model="korbans.record.name"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols=12>
              <v-select
                label="Jenis Kelamin"
                :color="theme.color"
                outlined
                dense
                hide-details
                v-model="korbans.record.jenis_kelamin"
                :items="korbans.jeniskelamins"
              ></v-select>
            </v-col>
            <v-col col="12">
              <v-text-field
                outlined
                :color="theme.color"
                hide-details
                label="Umur/Usia"
                v-model="korbans.record.umur"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols=12>
              <v-select
                label="Status"
                :color="theme.color"
                outlined
                dense
                hide-details
                v-model="korbans.record.status"
                :items="korbans.status"
              ></v-select>
            </v-col>

            <v-col cols=12>
              <v-textarea
                label="Alamat"
                :color="theme.color"
                outlined
                dense
                hide-details
                v-model="korbans.record.alamat"
                rows=3
              >{{ korbans.record.alamat  }}</v-textarea>
            </v-col>
            <v-col cols=12>
              <v-textarea
                label="Keterangan/Kondisi Korban"
                :color="theme.color"
                outlined
                dense
                hide-details
                v-model="korbans.record.keterangan"
                rows=3
              >{{ korbans.record.keterangan  }}</v-textarea>
            </v-col>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
              outlined
              :color="theme.color"
              v-show="!edit"
              @click="postKorban"
            >Tambah</v-btn>
            <v-btn
              outlined
              :color="theme.color"
              v-show="edit"
              @click="postUpdateRecord"
            >Ubah</v-btn>
            <v-btn
              outlined
              color="grey"
              @click="korbans.form.show = false"
            >Batal</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "profil-perusahaan",
  data: () => ({
    akta_pendirian: null,
    districts: [],
    villages: [],
    record: {},
    dokumen: {
      headers: [
        { text: "#", value: "num", width: 50 },
        { text: "DOKUMEN", value: "name" },
        { text: "AKSI", value: "id", width: 120 },
      ],
      records: [],
      record: {},
      form: {
        show: false,
      },
      path: null,
    },

    korbans: {
      headers: [
        { text: "#", value: "num", width: 50 },
        { text: "NIK", value: "nik", width: 200, sortable: false },
        { text: "NAMA", value: "name" },
        {
          text: "L/P",
          value: "jenis_kelamin",
          width: 100,
          align: "center",
          sortable: false,
        },
        {
          text: "UMUR",
          value: "umur",
          width: 100,
          align: "center",
          sortable: false,
        },
        {
          text: "STATUS",
          value: "status",
          width: 300,
          align: "center",
          sortable: false,
        },
        { text: "AKSI", value: "id", width: 100, sortable: false },
      ],
      records: [],
      record: {},
      search: null,
      form: {
        show: false,
      },
      jeniskelamins: [
        { text: "Laki-laki", value: "L" },
        { text: "Perempuan", value: "P" },
      ],
      status: [
        { text: "Kepala Keluarga", value: "Kepala Keluarga" },
        { text: "Anggota Keluarga", value: "Anggota Keluarga" },
      ],
    },

    tab: null,
  }),

  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "records",
      "loading",
      "event",
      "snackbar",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/",
      pagination: false,
      title: "TINDAK LANJUT KEBAKARAN",
      subtitle: "Formulir Tindak Lanjut Laporan Kebakaran",

      breadcrumbs: [
        {
          text: "Formulir Tindak Lanjut Kebakaran",
          disabled: true,
          href: "",
        },
      ],
      add: false,
      edit: false,
      actions: {
        refresh: true,
        add: true,
        edit: true,
        delete: true,
        bulkdelete: false,
        export: false,
        print: false,
      },
    });

    this.fetchRecord();
    this.fetchDokumen();
    this.fetchKorbans();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "assignFileBrowse",
      "getUserInfo",
      "initUploadLibrary",
      "setRecord",
      "setLoading",
    ]),
    fetchRecord: async function () {
      let { data } = await this.http.get(
        "api/operator/tindak-lanjut/" + this.$route.params.pengaduan_id
      );
      this.record = data;
      this.dokumen.records = data.documents;
      this.instruments.records = data.instruments;
    },
    postRecord: async function () {
      try {
        this.setLoading({ dialog: true, text: "Proses Simpan Tindak Lanjut" });
        let {
          data: { status, message },
        } = await this.http.post(
          "api/operator/tindak-lanjut/" + this.$route.params.pengaduan_id,
          this.record
        );
        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = "Opps..., terjadi kesalahan " + error;
        this.snackbar.state = true;
      } finally {
        this.setLoading({ dialog: false, text: "" });
      }
    },
    fetchUpdate: async function () {
      try {
        this.setLoading({
          dialog: true,
          text: "Proses verifikasi data permohonan",
        });
        let {
          data: { status, message, data },
        } = await this.http.post(
          "api/supervisor/pemeriksaan-permohonan/" +
            this.$route.params.id +
            "?_method=PUT",
          this.record
        );

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;

        this.record = data;
        this.$router.push({ name: "supervisor-verifikasi-permohonan-index" });
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = error;
        this.snackbar.state = true;
      } finally {
        this.setLoading({ dialog: false, text: "" });
      }
    },

    uploadFile: function () {
      this.assignFileBrowse({
        fileType: [".jpeg", ".jpg"],
        query: {
          doctype: "dokumen",
        },
        callback: (response) => {
          setTimeout(() => {
            this.dokumen.path = response.name;
            this.dokumen.record.path = response.name;
          }, 1000);
        },
      });
    },

    postDownload: async function (val) {
      //window.open(val, "__blank");
      try {
        this.setLoading({ dialog: true, text: "Proses lihat dokumen" });
        let {
          data: { path },
        } = await this.http.post("api/supervisor/download-dokumen", {
          id: val,
        });

        window.open(path, "__blank");
      } catch (error) {
      } finally {
        this.setLoading({ dialog: false, text: "" });
      }
    },

    openDokumen: function () {
      this.dokumen.form.show = true;
    },
    fetchDokumen: async function () {
      try {
        let { data } = await this.http.get(
          "api/operator/dokumen/" + this.$route.params.pengaduan_id
        );
        this.dokumen.records = data;
      } catch (error) {}
    },

    postDokumenLaporan: async function () {
      try {
        this.setLoading({ dialog: true, text: "Proses Upload Dokumen" });
        let {
          data: { status, message },
        } = await this.http.post(
          "api/operator/dokumen/" + this.$route.params.pengaduan_id,
          this.dokumen.record
        );
        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
        this.dokumen.form.show = false;
        this.fetchDokumen();
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = "Opps..., terjadi kesalahan " + error;
        this.snackbar.state = true;
      } finally {
        this.setLoading({ dialog: false, text: "" });
      }
    },

    openKorban: function () {
      this.korbans.record = {};
      this.korbans.form.show = true;
    },

    fetchKorbans: async function () {
      try {
        let { data } = await this.http.get(
          "api/operator/korban/" + this.$route.params.pengaduan_id
        );
        this.korbans.records = data;
      } catch (error) {}
    },

    postKorban: async function () {
      try {
        this.setLoading({ dialog: true, text: "Proses tambah korban" });
        let {
          data: { status, message },
        } = await this.http.post(
          "api/operator/korban/" + this.$route.params.pengaduan_id,
          this.korbans.record
        );

        if (!status) {
          this.snackbar.color = "orange";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
        this.fetchKorbans();
        this.korbans.form.show = false;
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = "Opps..., terjadi kesalahan " + error;
        this.snackbar.state = true;
      } finally {
        this.setLoading({ dialog: false, text: "" });
      }
    },
    postDeleteKorban: async function (val) {
      try {
        let {
          data: { status, message },
        } = await this.http.post(
          "api/operator/korban/" +
            this.$route.params.pengaduan_id +
            "/" +
            val +
            "?_method=DELETE"
        );
        if (!status) {
          this.snackbar.color = "orange";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
        this.fetchKorbans();
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = "Opps..., terjadi kesalahan " + error;
        this.snackbar.state = true;
      }
    },
  },
  watch: {
    "record.district_id": function () {
      this.fetchVillages();
    },
  },
};
</script>

<style></style>
