import Vue from "vue";
import VueRouter from "vue-router";
import Auth from "../providers/AuthProviders";
import Login from "../views/auth/Login.vue";
import Register from "../views/auth/Register.vue";
import Home from "../views/frontend/Home.vue";

//BASE APLIKASI
import Base from "../views/backend/Base.vue";

//Dashboard Page
import Dashboard from "../views/backend/pages/Dashboard.vue";

//Utiity Group Page
import User from "../views/backend/pages/utility/user/Index.vue";
import Changepwd from "../views/backend/pages/utility/user/Changepwd.vue";
import ProfilAkun from "../views/backend/pages/utility/user/Profil.vue";
import UtilityManajemenFitur from "../views/backend/pages/utility/manajemenfitur/Index.vue";
import UtilityManajemenFiturAdministrator from "../views/backend/pages/utility/manajemenfitur/administrator/Index.vue";

//page master data
import MasterAppInfo from "../views/backend/pages/masterdata/appinfo/Index.vue";
import MasterJenisKejadian from "../views/backend/pages/masterdata/jeniskejadian/Index.vue";
import MasterKejadian from "../views/backend/pages/masterdata/jeniskejadian/kejadian/Index.vue";
import MasterPosJaga from "../views/backend/pages/masterdata/posjaga/Index.vue";

//Backend Halaman Depan
import HalamanDepanSlider from "../views/backend/pages/halamandepan/sliders/Index.vue";
import HalamanDepanPengumuman from "../views/backend/pages/halamandepan/pengumuman/Index.vue";

/**
 * Route Kemanada regu
 */
import DanruPengaduan from "../views/backend/pages/danru/pengaduan/index.vue";
import DanruPengaduanEdit from "../views/backend/pages/danru/pengaduan/Edit.vue";
import DanruTindaLanjutKebakaran from "../views/backend/pages/danru/tindaklanjut/kebakaran/Index.vue";
import DanruTindaLanjutKebencanaan from "../views/backend/pages/danru/tindaklanjut/kebencanaan/Index.vue";
import DanruTindaLanjutPenyelamatan from "../views/backend/pages/danru/tindaklanjut/penyelamatan/Index.vue";

/**
 * Route Oprator
 *
 */
import OperatorPengaduan from "../views/backend/pages/operator/pengaduan/Index.vue";
import OperatorPengaduanCreate from "../views/backend/pages/operator/pengaduan/Create.vue";
import OperatorPengaduanEdit from "../views/backend/pages/operator/pengaduan/Edit.vue";
import OperatorTindakLanjutKebencanaan from "../views/backend/pages/operator/tindaklanjut/kebencanaan/Index.vue";
import OperatorTindakLanjutKebakaran from "../views/backend/pages/operator/tindaklanjut/kebakaran/Index.vue";
import OperatorTindakLanjutPenyelamatan from "../views/backend/pages/operator/tindaklanjut/penyelamatan/Index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },

  {
    path: "/backend",
    name: "",
    meta: {
      auth: true,
    },
    component: Base,
    children: [
      { path: "", redirect: { name: "dashboard" } },
      { path: "dashboard", name: "dashboard", component: Dashboard },
      { path: "user", name: "user", component: User },
      {
        path: "chngpwd",
        name: "chngpwd",
        component: Changepwd,
      },
      {
        path: "profil-akun",
        name: "profil-akun",
        component: ProfilAkun,
      },
      //route master data
      {
        path: "master-app-info",
        name: "master-app-info",
        component: MasterAppInfo,
      },
      {
        path: "master-jenis-kejadian",
        name: "master-jenis-kejadian",
        component: MasterJenisKejadian,
      },
      {
        path: "master-kejadian/:jenis_kejadian_id",
        name: "master-kejadian",
        component: MasterKejadian,
      },
      {
        path: "master-pos-jaga",
        name: "master-pos-jaga",
        component: MasterPosJaga,
      },

      /**
       * Route Manajemen Halaman Depan
       */

      //Route slider
      {
        path: "halaman-depan-slider",
        name: "halaman_depan_slider",
        component: HalamanDepanSlider,
      },
      //Route Pengumuman
      {
        path: "halaman-depan-pengumuman",
        name: "halaman-depan-pengumuman",
        component: HalamanDepanPengumuman,
      },

      /** Route Danru */
      {
        path: "danru-pengaduan",
        name: "danru-pengaduan",
        component: DanruPengaduan,
      },
      {
        path: "danru-pengaduan-edit/:id",
        name: "danru-pengaduan-edit",
        component: DanruPengaduanEdit,
      },
      {
        path: "danru-tindak-lanjut-kebakaran/:pengaduan_id",
        name: "danru-tindak-lanjut-kebakaran",
        component: DanruTindaLanjutKebakaran,
      },
      {
        path: "danru-tindak-lanjut-kebencanaan/:pengaduan_id",
        name: "danru-tindak-lanjut-kebencanaan",
        component: DanruTindaLanjutKebencanaan,
      },
      {
        path: "danru-tindak-lanjut-penyelamatan/:pengaduan_id",
        name: "danru-tindak-lanjut-penyelamatan",
        component: DanruTindaLanjutPenyelamatan,
      },

      /**
       * Route Operator
       */

      {
        path: "operator-pengaduan",
        name: "operator-pengaduan",
        component: OperatorPengaduan,
      },
      {
        path: "operator-pengaduan-create",
        name: "operator-pengaduan-create",
        component: OperatorPengaduanCreate,
      },
      {
        path: "operator-pengaduan-edit/:id",
        name: "operator-pengaduan-edit",
        component: OperatorPengaduanEdit,
      },
      {
        path: "operator-tindak-lanjut-kebencanaan/:pengaduan_id",
        name: "operator-tindak-lanjut-kebencanaan",
        component: OperatorTindakLanjutKebencanaan,
      },
      {
        path: "operator-tindak-lanjut-kebakaran/:pengaduan_id",
        name: "operator-tindak-lanjut-kebakaran",
        component: OperatorTindakLanjutKebakaran,
      },
      {
        path: "operator-tindak-lanjut-penyelamatan/:pengaduan_id",
        name: "operator-tindak-lanjut-penyelamatan",
        component: OperatorTindakLanjutPenyelamatan,
      },

      /**
       * Route utility
       */
      {
        path: "utility-manajemen-fitur",
        name: "utility-manajemen-fitur",
        component: UtilityManajemenFitur,
      },
      {
        path: "utility-manajemen-fitur-administrator",
        name: "utility-manajemen-fitur-administrator",
        component: UtilityManajemenFiturAdministrator,
      },
    ],
  },

  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  mode: "history",
});

/**
 * router match
 */

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.auth);

  if (requiresAuth) {
    if (!Auth.check) {
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    if (to.name === "login" && Auth.check) {
      if (Auth.user.authent == "skpd") {
        next({ name: "skpd-dashboard" });
      } else if (Auth.user.auth === "supevisor") {
        next({ name: "supevisor-dashboard" });
      } else if (Auth.user.auth === "kabag") {
        next({ name: "kabag-dashboard" });
      } else if (Auth.user.auth === "staf") {
        next({ name: "staf-dashboard" });
      } else if (Auth.user.auth === "setda") {
        next({ name: "setda-dashboard" });
      } else {
        next({ name: "dashboard" });
      }
    } else {
      next();
    }
  }
});

export default router;
