<template>
  <div class="home pa-6">
    <v-flex
      row
      class="pb-2"
    >
      <v-icon :color="theme.color">mdi-hand</v-icon>
      <v-subheader :class="device.desktop ? `text-h6` : `text-small`">TINDAK LANJUT KEBENCANAAN
      </v-subheader>
    </v-flex>
    <v-flex class="pb-5 pl-7">
      <div class="grey--text mb-2">Formulir Tindak Lanjut Kebencanaan</div>
    </v-flex>

    <v-row class="pa-1">
      <v-spacer></v-spacer>
      <v-col :cols="device.desktop ? `12` : `12`">
        <v-card>
          <v-card-title
            :class="`white--text  ` + theme.color + ` Plighten-1`"
            style="font-size: 12pt"
          >
            <v-icon
              class="mr-2"
              color="white"
            >mdi-checkbox-blank-circle</v-icon>
            &nbsp;
            <span v-show="device.desktop">

              Formulir Tindak Lanjut Laporan Kebencanaan
            </span>
            <span v-show="device.mobile">
              Formulir
            </span>
          </v-card-title>

          <v-card-text class="mt-5 font-weight-normal">
            <v-tabs
              v-model="tab"
              :color="theme.color"
            >
              <v-tabs-slider></v-tabs-slider>
              <v-tab href="#tab-1">DATA TINDAK LANJUT</v-tab>
              <v-tab href="#tab-2">UNGGAH LAMPIRAN</v-tab>
              <v-tab-item value="tab-1">
                <v-row class="mt-2">
                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">WAKTU PERSIAPAN</div>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col :cols="device.desktop ? 6:12">
                        <v-text-field
                          label="Dari Jam"
                          outlined
                          :filled="record.waktu_persiapan_mulai"
                          dense
                          hide-details
                          v-model="record.waktu_persiapan_mulai"
                          :color="theme.color"
                          type="time"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ? 6:12">
                        <v-text-field
                          label="Sampai Dengan Jam"
                          outlined
                          :filled="record.waktu_persiapan_selesai"
                          dense
                          hide-details
                          v-model="record.waktu_persiapan_selesai"
                          :color="theme.color"
                          type="time"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">KEBERANGKATAN</div>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col :cols="device.desktop ? 6:12">
                        <v-text-field
                          label="Jam Keberangkatan"
                          outlined
                          :filled="record.waktu_keberangkatan"
                          dense
                          hide-details
                          v-model="record.waktu_keberangkatan"
                          :color="theme.color"
                          type="time"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ? 6:12">
                        <v-text-field
                          label="Jam Sampai Dilokasi"
                          outlined
                          :filled="record.waktu_sampai_dilokasi"
                          dense
                          hide-details
                          v-model="record.waktu_sampai_dilokasi"
                          :color="theme.color"
                          type="time"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">PENANGANAN</div>
                  </v-col>
                  <v-col cols=12>
                    <v-row>
                      <v-col :cols="device.desktop ?  6:12">
                        <v-text-field
                          label="Tanggal Mulai Penangan"
                          outlined
                          :filled="record.dari_tanggal"
                          dense
                          hide-details
                          v-model="record.dari_tanggal"
                          :color="theme.color"
                          type="date"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ?  6:12">
                        <v-text-field
                          label="Tanggal Akhir Penangan"
                          outlined
                          :filled="record.sampai_tanggal"
                          dense
                          hide-details
                          v-model="record.sampai_tanggal"
                          :color="theme.color"
                          type="date"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col :cols="device.desktop ? 6:12">
                        <v-text-field
                          label="Mulai Penangan"
                          outlined
                          :filled="record.waktu_penanganan_mulai"
                          dense
                          hide-details
                          v-model="record.waktu_penanganan_mulai"
                          :color="theme.color"
                          type="time"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ? 6:12">
                        <v-text-field
                          label="Selesai Penangan"
                          outlined
                          :filled="record.waktu_penanganan_selesai"
                          dense
                          hide-details
                          v-model="record.waktu_penanganan_selesai"
                          :color="theme.color"
                          type="time"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col :cols="device.desktop ? 6:12">
                        <v-text-field
                          label="Lama Penanganan"
                          outlined
                          :filled="record.lama_penanganan_jam"
                          dense
                          hide-details
                          v-model="record.lama_penanganan_jam"
                          :color="theme.color"
                          suffix="JAM"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ? 6:12">
                        <v-text-field
                          label="Lama Penanganan"
                          outlined
                          :filled="record.lama_penanganan_menit"
                          dense
                          hide-details
                          v-model="record.lama_penanganan_menit"
                          :color="theme.color"
                          suffix="MENIT"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">KORBAN</div>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col :cols="device.desktop ? 4: 12">
                        <v-text-field
                          label="Korban Selamat"
                          outlined
                          :filled="record.korban_selamat"
                          dense
                          hide-details
                          v-model="record.korban_selamat"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ? 4: 12">
                        <v-text-field
                          label="Korban Luka Luka"
                          outlined
                          :filled="record.korban_luka_luka"
                          dense
                          hide-details
                          v-model="record.korban_luka_luka"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ? 4: 12">
                        <v-text-field
                          label="Korban Meninggal"
                          outlined
                          :filled="record.korban_meninggal"
                          dense
                          hide-details
                          v-model="record.korban_meninggal"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">
                      JUMLAH WARGA YANG TERDAMPAK
                    </div>
                  </v-col>
                  <v-col cols=12>
                    <v-row>
                      <v-col :cols="device.desktop ? 6:12">
                        <v-text-field
                          label="Laki - Laki"
                          outlined
                          :filled="record.jumlah_warga_yang_terdampak_l"
                          dense
                          hide-details
                          v-model="record.jumlah_warga_yang_terdampak_l"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ? 6:12">
                        <v-text-field
                          label="Perempuan"
                          outlined
                          :filled="record.jumlah_warga_yang_terdampak_p"
                          dense
                          hide-details
                          v-model="record.jumlah_warga_yang_terdampak_p"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col :cols="device.desktop ? 4:12">
                        <v-text-field
                          label="Usia di bawah 1 Tahun"
                          outlined
                          :filled="record.jumlah_warga_yang_terdampak_1"
                          dense
                          hide-details
                          v-model="record.jumlah_warga_yang_terdampak_1"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ? 4:12">
                        <v-text-field
                          label="Usia di 5 s.d 16 Tahun"
                          outlined
                          :filled="record.jumlah_warga_yang_terdampak_5sd16"
                          dense
                          hide-details
                          v-model="record.jumlah_warga_yang_terdampak_5sd16"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ? 4:12">
                        <v-text-field
                          label="Usia 16 sd 60 Tahun"
                          outlined
                          :filled="record.jumlah_warga_yang_terdampak_16sd60"
                          dense
                          hide-details
                          v-model="record.jumlah_warga_yang_terdampak_16sd60"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col :cols="device.desktop ? 4:12">
                        <v-text-field
                          label="Usia di atas 60 Tahun"
                          outlined
                          :filled="record.jumlah_warga_yang_terdampak_60"
                          dense
                          hide-details
                          v-model="record.jumlah_warga_yang_terdampak_60"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col :cols="device.desktop ? 4:12">
                        <v-text-field
                          label="Jumlah Wanita Hamil"
                          outlined
                          :filled="record.jumlah_warga_yang_terdampak_wanita_hamil"
                          dense
                          hide-details
                          v-model="record.jumlah_warga_yang_terdampak_wanita_hamil"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ? 4:12">
                        <v-text-field
                          label="Jumlah Wanita Menyusui"
                          outlined
                          :filled="record.jumlah_warga_yang_terdampak_wanita_menyusui"
                          dense
                          hide-details
                          v-model="record.jumlah_warga_yang_terdampak_wanita_menyusui"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ? 4:12">
                        <v-text-field
                          label="Jumlah Difabel"
                          outlined
                          :filled="record.jumlah_warga_yang_terdampak_difabel"
                          dense
                          hide-details
                          v-model="record.jumlah_warga_yang_terdampak_difabel"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">
                      JUMLAH WARGA DISEKITAR LOKASI
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col :cols="device.desktop ? 6:12">
                        <v-text-field
                          label="Laki - Laki"
                          outlined
                          :filled="record.jumlah_warga_disekitar_lokasi_l"
                          dense
                          hide-details
                          v-model="record.jumlah_warga_disekitar_lokasi_l"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ? 6:12">
                        <v-text-field
                          label="Perempuan"
                          outlined
                          :filled="record.jumlah_warga_disekitar_lokasi_p"
                          dense
                          hide-details
                          v-model="record.jumlah_warga_disekitar_lokasi_p"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ?4:12">
                        <v-text-field
                          label="Usia Dibawah 1 Tahun"
                          outlined
                          :filled="
                            record.jumlah_warga_disekitar_lokasi_usia_kurang_1
                          "
                          dense
                          hide-details
                          v-model="
                            record.jumlah_warga_disekitar_lokasi_usia_kurang_1
                          "
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ?4:12">
                        <v-text-field
                          label="Usia 5 s.d 16 Tahun"
                          outlined
                          :filled="
                            record.jumlah_warga_disekitar_lokasi_usia_5_16
                          "
                          dense
                          hide-details
                          v-model="
                            record.jumlah_warga_disekitar_lokasi_usia_5_16
                          "
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ?4:12">
                        <v-text-field
                          label="Usia 16 s.d 60 Tahun"
                          outlined
                          :filled="
                            record.jumlah_warga_disekitar_lokasi_usia_16_60
                          "
                          dense
                          hide-details
                          v-model="
                            record.jumlah_warga_disekitar_lokasi_usia_16_60
                          "
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ?4:12">
                        <v-text-field
                          label="Usia diatas 60 Tahun"
                          outlined
                          :filled="
                            record.jumlah_warga_disekitar_lokasi_usia_diatas_60
                          "
                          dense
                          hide-details
                          v-model="
                            record.jumlah_warga_disekitar_lokasi_usia_diatas_60
                          "
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ?4:12">
                        <v-text-field
                          label="Jumlah Wanita Hamil"
                          outlined
                          :filled="record.jumlah_warga_disekitar_lokasi_hamil"
                          dense
                          hide-details
                          v-model="record.jumlah_warga_disekitar_lokasi_hamil"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ?4:12">
                        <v-text-field
                          label="Jumlah Ibu Menyusui"
                          outlined
                          :filled="record.jumlah_warga_disekitar_lokasi_ibu_menyusui"
                          dense
                          hide-details
                          v-model="record.jumlah_warga_disekitar_lokasi_ibu_menyusui"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ?4:12">
                        <v-text-field
                          label="Jumlah Difabel"
                          outlined
                          :filled="record.jumlah_warga_disekitar_lokasi_difabel"
                          dense
                          hide-details
                          v-model="record.jumlah_warga_disekitar_lokasi_difabel"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">LOGISTIK</div>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Kebutuhan Logistik"
                      outlined
                      dense
                      hide-details
                      rows="3"
                      v-model="record.kebutuhan_logistik"
                      :color="theme.color"
                    >{{ record.kebutuhan_logistik }}</v-textarea>
                  </v-col>

                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">TEAM PENANGANAN</div>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      label="Jumlah Petugas Yang Menangani"
                      outlined
                      :filled="record.jumlah_petugas"
                      dense
                      hide-details
                      v-model="record.jumlah_petugas"
                      :color="theme.color"
                      type="number"
                      suffix="ORANG"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Asal Pos Yang Menangani"
                      outlined
                      dense
                      hide-details
                      rows="2"
                      v-model="record.asal_pos"
                      :color="theme.color"
                    >{{ record.asal_pos }}</v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Unsur Yang Terlibat Penanganan"
                      outlined
                      dense
                      hide-details
                      rows="2"
                      v-model="record.unsur_yang_terlibat"
                      :color="theme.color"
                    >{{ record.unsur_yang_terlibat }}</v-textarea>
                  </v-col>

                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">PERALATAN</div>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Peralatan Yang Dibutuhkan"
                      outlined
                      dense
                      hide-details
                      rows="2"
                      v-model="record.peralatan_yang_digunakan"
                      :color="theme.color"
                    >{{ record.peralatan_yang_digunakan }}</v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <div class="subtitle-2 grey--text">KONDISI</div>
                  </v-col>
                  <v-col cols=12>
                    <v-row>
                      <v-col :cols="device.desktop ? 6 : 12">
                        <v-text-field
                          label="Jumlah Prasarana Yang Rusak"
                          outlined
                          :filled="record.jumlah_prasarana_rusak"
                          dense
                          hide-details
                          v-model="record.jumlah_prasarana_rusak"
                          :color="theme.color"
                          type="number"
                          suffix="Unit"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ? 6 : 12">
                        <v-text-field
                          label="Tingkat Kerusakan"
                          outlined
                          :filled="record.tingkat_kerusakan"
                          dense
                          hide-details
                          v-model="record.tingkat_kerusakan"
                          :color="theme.color"
                          type="number"
                          suffix="(%)"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ? 6 : 12">
                        <v-text-field
                          label="Jumlah Masyarakat Yang Mendapat Logistik"
                          outlined
                          :filled="record.jumlah_masyarakat_yang_mendapat_logistik"
                          dense
                          hide-details
                          v-model="record.jumlah_masyarakat_yang_mendapat_logistik"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                      <v-col :cols="device.desktop ? 6 : 12">
                        <v-text-field
                          label="Jumlah Masyarakat Yang Dievakuasi"
                          outlined
                          :filled="record.jumlah_masyarakat_yang_dievakuasi"
                          dense
                          hide-details
                          v-model="record.jumlah_masyarakat_yang_dievakuasi"
                          :color="theme.color"
                          type="number"
                          suffix="ORANG"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12">
                    <v-textarea
                      label="Kondisi Saat Ini"
                      outlined
                      dense
                      hide-details
                      rows="3"
                      v-model="record.situasi_terkini"
                      :color="theme.color"
                    >{{ record.situasi_terkini }}</v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Permasalahan Yang Dihadapi"
                      outlined
                      dense
                      hide-details
                      rows="3"
                      v-model="record.permasalahan_yang_dihadapi"
                      :color="theme.color"
                    >{{ record.permasalahan_yang_dihadapi }}</v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      label="Saran-saran"
                      outlined
                      dense
                      hide-details
                      rows="3"
                      v-model="record.saran_saran"
                      :color="theme.color"
                    >{{ record.saran_saran }}</v-textarea>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item value="tab-2">
                <v-row class="mt-2">
                  <v-col cols="12">
                    <div class="h6">HASIL DOKUMENTASI</div>
                  </v-col>
                  <v-col cols="12">
                    <v-row class="justify-end mr-2">
                      <v-tooltip
                        :color="theme.color"
                        bottom
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn
                            text
                            small
                            icon
                            v-on="on"
                          >
                            <v-icon
                              :color="theme.color"
                              @click="openDokumen"
                            >mdi-plus-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>Tambah Dokumen Laporan</span>
                      </v-tooltip>
                    </v-row>
                  </v-col>
                  <v-col cols="12">
                    <v-data-table
                      :headers="dokumen.headers"
                      :items="dokumen.records"
                      :items-per-page="10"
                      class="elevation-2"
                      :color="theme"
                      :loading="loading.table"
                      loading-text="Loading... Please wait"
                      :search="dokumen.search"
                    >
                      <v-progress-linear
                        slot="progress"
                        :color="theme.color"
                        height="1"
                        indeterminate
                      ></v-progress-linear>

                      <template v-slot:item.flname_path="{ value }">
                        <v-icon
                          :color="theme.color"
                          @click="postDownload(value)"
                        >cloud_download</v-icon>
                      </template>

                      <template v-slot:item.id="{ value }">
                        <v-tooltip
                          color="purple"
                          bottom
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              text
                              small
                              icon
                              v-on="on"
                            >
                              <v-icon
                                color="purple"
                                @click="postDownload(value)"
                              >mdi-cloud-download</v-icon>
                            </v-btn>
                          </template>
                          <span>Lihat Dokumen</span>
                        </v-tooltip>
                        <v-tooltip
                          color="green"
                          bottom
                        >
                          <template v-slot:activator="{ on }">
                            <v-btn
                              text
                              small
                              icon
                              v-on="on"
                            >
                              <v-icon
                                color="red"
                                @click="postDeleteRecord(value)"
                              >mdi-delete-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Hapus</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-col class="text-right">
              <v-btn
                outlined
                :color="theme.color"
                @click="postRecord"
              >
                <v-icon>mdi-content-save</v-icon> SIMPAN
              </v-btn>
              <v-btn
                class="ml-2"
                outlined
                color="grey"
                @click="
                  $router.push({
                    name: 'danru-pengaduan',
                  })
                "
              >
                <v-icon>cancel</v-icon>TUTUP
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-col col="12">
      <v-dialog
        transition="dialog-bottom-transition"
        v-model="dokumen.form.show"
        :max-width="device.desktop ? `600px` : `100%`"
        persistent
      >
        <v-card>
          <v-toolbar
            :color="theme.color"
            dark
          >Formulir Upoad Dokumen Laporan</v-toolbar>
          <v-card-text>
            <v-col
              col="12"
              class="mt-3"
            >
              <v-text-field
                outlined
                :color="theme.color"
                :hide-details="device.desktop"
                label="Nama Dokumen"
                v-model="dokumen.record.name"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="File Foto (Max: 2 MB)"
                append-outer-icon="attachment"
                v-model="dokumen.path"
                :color="theme.color"
                @click:append-outer="uploadFile"
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
              outlined
              :color="theme.color"
              v-show="!edit"
              @click="postDokumenLaporan"
            >Tambah</v-btn>
            <v-btn
              outlined
              :color="theme.color"
              v-show="edit"
              @click="postUpdateRecord"
            >Ubah</v-btn>
            <v-btn
              outlined
              color="grey"
              @click="dokumen.form.show = false"
            >Batal</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "profil-perusahaan",
  data: () => ({
    akta_pendirian: null,
    districts: [],
    villages: [],
    record: {},
    dokumen: {
      headers: [
        { text: "#", value: "num", width: 50 },
        { text: "DOKUMEN", value: "name" },
        { text: "AKSI", value: "id", width: 120 },
      ],
      records: [],
      record: {},
      form: {
        show: false,
      },
      path: null,
    },
    instruments: {
      headers: [
        { text: "#", value: "num", width: 50 },
        { text: "ITEM", value: "name" },
        { text: "QTY/VOL", value: "qty", width: 120, align: "right" },
        {
          text: "NILAI RETRIBUSI",
          value: "nilai_retribusi",
          width: 200,
          align: "right",
        },
      ],
      records: [],
      record: {},
      search: null,
    },
    tab: null,

    kasis: [],

    jeniskendaraans: [
      { text: "MOBIL", value: "MOBIL" },
      { text: "MOTOR", value: "MOTOR" },
    ],
    inspekturs: [],
    anggotas: [],
    alats: [],
    status: [
      { text: "Menunggu Jadwal Inpeksi", value: 5 },
      { text: "Perlu Perbaikan Data", value: 4 },
    ],
  }),

  computed: {
    ...mapState([
      "theme",
      "http",
      "device",
      "records",

      "loading",
      "event",
      "snackbar",
    ]),
  },
  created() {
    this.setPage({
      crud: true,
      dataUrl: "api/",
      pagination: false,
    });

    this.fetchRecord();
    this.fetchDokumen();
  },
  mounted() {},
  methods: {
    ...mapActions([
      "setPage",
      "assignFileBrowse",
      "getUserInfo",
      "initUploadLibrary",
      "setRecord",
      "setLoading",
    ]),
    fetchRecord: async function () {
      let { data } = await this.http.get(
        "api/operator/tindak-lanjut/" + this.$route.params.pengaduan_id
      );
      this.record = data;
      this.dokumen.records = data.documents;
      this.instruments.records = data.instruments;
    },
    postRecord: async function () {
      try {
        this.setLoading({ dialog: true, text: "Proses Simpan Tindak Lanjut" });
        let {
          data: { status, message },
        } = await this.http.post(
          "api/operator/tindak-lanjut/" + this.$route.params.pengaduan_id,
          this.record
        );
        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = "Opps..., terjadi kesalahan " + error;
        this.snackbar.state = true;
      } finally {
        this.setLoading({ dialog: false, text: "" });
      }
    },
    fetchUpdate: async function () {
      try {
        this.setLoading({
          dialog: true,
          text: "Proses verifikasi data permohonan",
        });
        let {
          data: { status, message, data },
        } = await this.http.post(
          "api/supervisor/pemeriksaan-permohonan/" +
            this.$route.params.id +
            "?_method=PUT",
          this.record
        );

        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;

        this.record = data;
        this.$router.push({ name: "supervisor-verifikasi-permohonan-index" });
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = error;
        this.snackbar.state = true;
      } finally {
        this.setLoading({ dialog: false, text: "" });
      }
    },

    uploadFile: function () {
      this.assignFileBrowse({
        fileType: [".jpeg", ".jpg"],
        query: {
          doctype: "dokumen",
        },
        callback: (response) => {
          setTimeout(() => {
            this.dokumen.path = response.name;
            this.dokumen.record.path = response.name;
          }, 1000);
        },
      });
    },

    postDownload: async function (val) {
      //window.open(val, "__blank");
      try {
        this.setLoading({ dialog: true, text: "Proses lihat dokumen" });
        let {
          data: { path },
        } = await this.http.post("api/supervisor/download-dokumen", {
          id: val,
        });

        window.open(path, "__blank");
      } catch (error) {
      } finally {
        this.setLoading({ dialog: false, text: "" });
      }
    },

    openDokumen: function () {
      this.dokumen.form.show = true;
    },
    fetchDokumen: async function () {
      try {
        let { data } = await this.http.get(
          "api/operator/dokumen/" + this.$route.params.pengaduan_id
        );
        this.dokumen.records = data;
      } catch (error) {}
    },

    postDokumenLaporan: async function () {
      try {
        this.setLoading({ dialog: true, text: "Proses Upload Dokumen" });
        let {
          data: { status, message },
        } = await this.http.post(
          "api/operator/dokumen/" + this.$route.params.pengaduan_id,
          this.dokumen.record
        );
        if (!status) {
          this.snackbar.color = "red";
          this.snackbar.text = message;
          this.snackbar.state = true;
          return;
        }

        this.snackbar.color = this.theme.color;
        this.snackbar.text = message;
        this.snackbar.state = true;
        this.dokumen.form.show = false;
        this.fetchDokumen();
      } catch (error) {
        this.snackbar.color = "red";
        this.snackbar.text = "Opps..., terjadi kesalahan " + error;
        this.snackbar.state = true;
      } finally {
        this.setLoading({ dialog: false, text: "" });
      }
    },
  },
  watch: {
    "record.district_id": function () {
      this.fetchVillages();
    },
  },
};
</script>

<style></style>
